import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { HomeComponent } from './cpos-modules/home/home.component';
import { MenuComponent } from './menu/menu.component';
import { NotfoundComponent } from './notfound/notfound.component';

const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', loadChildren: './cpos-modules/cpos.module#CPOSModule' },
    ]
  },
  // {
  //   path: 'home',
  //   component: HomeComponent,
  // },
  { path: 'auth', loadChildren: './auth/auth.module#AuthModule' },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule { }

// {
  //   path: '',
  //   component: MenuComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     { path: '', loadChildren: './cpos-modules/cpos.module#CPOSModule' },
  //   ]
  // },
  // {
  //   path: '',
  //   component: MenuComponent,
  //   children: [
  //     { path: 'dashboard', loadChildren: '../dashboard/dashboard.module#DashbordModule' },
  //     { path: 'user', loadChildren: './admin/admin.module#AdminModule' },
  //     { path: 'master', loadChildren: './master/master.module#MasterModule' },
  //     { path: 'vp', loadChildren: './vendor/vendor.module#VendorModule' },
  //     { path: 'report', loadChildren: './reports/reports.module#ReportsModule' },
  //     { path: 'dashboard', loadChildren: '../dashboard/dashboard.module#DashbordModule' },
  //     { path: 'example', component: TestMaterialControlComponent },
  //     { path: 'user', loadChildren: './admin/admin.module#AdminModule' },
  //     { path: 'master', loadChildren: './master/master.module#MasterModule' },
  //     { path: 'vp', loadChildren: './vendor/vendor.module#VendorModule' },
  //     { path: 'report', loadChildren: './reports/reports.module#ReportsModule' },
  //   ]
  // },