import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material';

export const MY_MM_YYYY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_DATE_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'input',
    // monthYearLabel:'MMM YYYY',
    monthYearLabel: { year: 'numeric', month: 'short' },
    // dateA11yLabel:'LL',
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    // monthYearA11yLabel:'MMM YYYY',
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};

export const MY_DATE_FORMATSMMM = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'inputmmm',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};
export const MY_DATE_FORMATSMMMYYYY = {
  parse: { dateInput: { month: 'short', year: 'numeric' } },
  display: {
    dateInput: 'inputmmmyyyy',
    monthYearLabel: { year: 'numeric', month: 'short' },
    //dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};

@Injectable({
  providedIn: 'root'
})
export class IndianDateadapterService extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return new DatePipe('en-in').transform(date, 'dd/MM/yyyy');
    }
    else if(displayFormat === 'inputmmm'){
      return new DatePipe('en-in').transform(date, 'dd/MMM/yyyy');
    }
    else if(displayFormat === 'inputmmmyyyy'){
      return new DatePipe('en-in').transform(date, 'MMM/yyyy');
    }
     else {
      return date.toDateString();
    }
  }
}