import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { Logger } from './common-services/common-functions.service';

@Injectable()
export class AppInitialize {

    apiURL: string;
    gridItemsPerPage = 10;
    idleTime = 300;
    timeoutTime = 120;
    pingTime = 800;
    constructor(private http: HttpClient) { }

    getSettings(url): Promise<any> {
        const promise = this.http.get(url)
            .toPromise()
            .then(settings => {
                this.apiURL = settings['ApiUrl'];
                this.gridItemsPerPage = settings['GridItemsPerPage'];
                this.idleTime = settings['idleTime'];
                this.timeoutTime = settings['timeoutTime'];
                this.pingTime = settings['pingTime'];
                Logger.loggingEnabled = settings['EnableLog'];
            }).catch(error => {
                console.log(error);
            });
        return promise;
    }
}
