import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Logger } from './common-services/common-functions.service';
import { KEY_CODE, ShortCutKeys } from './common-services/enum.service';
import { SharedService } from './shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(private authService: AuthService, private sharedService: SharedService) { }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KEY_CODE.PAGE_UP:
        this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.PageUp, description: '' });
        break;
      case KEY_CODE.PAGE_DOWN:
        this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.PageDown, description: '' });
        break;
      case KEY_CODE.HOME:
        this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Home, description: '' });
        break;
      case KEY_CODE.END:
        this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.End, description: '' });
        break;
      case KEY_CODE.DELETE:
        this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Delete, description: '' });
        break;
      case KEY_CODE.ENTER:
        this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Enter, description: '' });
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    // this.sharedService.customGetApi('common/test').subscribe((t: any) => {
    //   if (t.data == 'ok') {
    //     if (this.authService.isAuthenticated) {
    //       Logger.Normal('Start From App Module');
    //       this.authService.initIdleService();

    //     }
    //   } else {
    //     this.authService.logOut('','auth/login');
    //   }
    // }, (error) => {
    //   console.log(error);
    //   this.authService.logOut('','auth/login');
    // });
  }

  ngAfterViewInit() { }

}
