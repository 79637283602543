import { VendorEnum, InputType } from "./enum.service";
import { IFilter, IHashTable } from "./interface.service";

import { TemplateRef } from "@angular/core";

export class CommonFilter implements IFilter {
    constructor(_vendorCode: string, _currentPage: VendorEnum = VendorEnum.None) {
        this.pageNumber = 1;
        this.pageSize = 10;
        this.isExport = false;
        this.currentPage = _currentPage;
        this.vendorCode = _vendorCode;
        // if (_companyId === 0) {
        //     this.companyId = CommonService.defaultComanyId;
        // } else {
        //     this.companyId = _companyId;
        // }
        // if (isNullOrUndefined(this.yearKey) && this.yearKey === 0) {
        //     this.yearKey = CommonService.defaultYearKey;
        // }
        // this.idOnly = false;
        this.filterArray = new Array<IHashTable>();
    }
    currentPage: VendorEnum;
    pageNumber: number;
    pageSize: number;
    isExport: boolean;
    fromDate: string;
    toDate: string;
    orderBy: string;
    searchField: string;
    searchValue: string;
    vendorCode: string;
    filterArray: Array<IHashTable>;
}

export class ApiResponse {
    status: boolean;
    message: string;
    data: any
}

export class StoreAdvanceSearch extends CommonFilter {
    Store_Name = '';
    Address = '';
    District = '';
    State = '';
    IsAllFilteredStoreSelect = false;
}

export class Documents {
    constructor(_documentName: string, _documentPath: string) {
        if (_documentPath && _documentName) {
            this.documentName = _documentName;
            this.documentPath = _documentPath;
        }
    }
    poNo = 0;
    documentPath = '';
    documentName = '';
    documentType = '';
    uploadDate = new Date();
    uploadBy = '';
    documentSize = '';
    objectURL = '';
}


export class TableObject {
    constructor(private _cellDef: Array<cellDef>, private _actionButton: number, _isServerSidePaging: boolean, _defaultSearchField: string, _deaultFilter: CommonFilter = null, _pageEnum: VendorEnum, _showHistory: boolean = false) {
        this.cellDefArray = _cellDef;
        this.actionButton = _actionButton;
        this.isServerSidePaging = _isServerSidePaging;
        this.defaultSearchField = _defaultSearchField;
        this.deaultFilter = _deaultFilter;
        this.pageEnum = _pageEnum;
        this.showHistory = _showHistory;
    }
    cellDefArray: Array<cellDef>
    actionButton: number;
    isServerSidePaging: boolean;
    defaultSearchField: string;
    deaultFilter: CommonFilter;
    pageEnum: VendorEnum;
    showHistory: boolean;
}

export class cellDef {
    constructor(_displayName, _headerName, _showInFieldDDl, _fieldType, _footerField, _tooltipField, _cellCSS = '', _subReportId = 0, _isDisplay = true) {
        this.displayName = _displayName;
        this.headerName = _headerName;
        this.showInFieldDDl = _showInFieldDDl;
        this.fieldType = _fieldType;
        this.footerField = _footerField;
        this.tooltipField = _tooltipField
        this.cellCSS = _cellCSS;
        this.subReportId = _subReportId;
        this.isDisplay = _isDisplay;
    }
    displayName: string;
    headerName: string;
    showInFieldDDl: boolean;
    fieldType: InputType;
    footerField: string;
    tooltipField: TemplateRef<any>;
    cellCSS: string;
    subReportId: Number;
    isDisplay = true;

}


