import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { isNullOrUndefined } from 'util';
import { ConfirmationHeader } from '../../common-services/enum.service';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.css']
})
export class ConfirmationPopupComponent implements OnInit {

  confirmation: ConfirmationModel;
  buttonText = { ok: 'Ok', cancel: '' };
  constructor(public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public confModel: ConfirmationModel) {
    if (isNullOrUndefined(confModel)) {
      this.confirmation = new ConfirmationModel(ConfirmationHeader.Confirmation, 'Are you sure want to proceed?');
    } else {
      this.confirmation = confModel;
    }
    if (isNullOrUndefined(this.confirmation.respObject)) {
      this.confirmation.respObject = true;
    }
    this.setButtonText(this.confirmation.buttonType);
  }

  ngOnInit() {
  }

  setButtonText(buttonType: ButtonType): any {
    switch (buttonType) {
      case ButtonType.Ok:
        this.buttonText = { ok: 'Ok', cancel: '' };
        break;
      case ButtonType.OkCancel:
        this.buttonText = { ok: 'Ok', cancel: 'Cancel' };
        break;
      case ButtonType.OkNoThanks:
        this.buttonText = { ok: 'Ok', cancel: 'No Thanks' };
        break;
      case ButtonType.YesNo:
        this.buttonText = { ok: 'Yes', cancel: 'No' };
        break;
        case ButtonType.GeneratePDFCancel:
        this.buttonText = { ok: 'Generate PDF', cancel: 'Back' };
        break;
      default:
        this.buttonText = { ok: 'Ok', cancel: '' };
        break;
    }
  }

  onOkClick() {
   
    if (isNullOrUndefined(this.confirmation.respObject)) {
      this.confirmation.respObject = true;
    }
    this.dialogRef.close(this.confirmation.respObject);
  }

  onCancelClick() {
    if (isNullOrUndefined(this.confirmation.respObject)) {
      this.confirmation.respObject = false;
    }
    this.dialogRef.close();
  }
  // ngOnDestroy(): void {
  //  
  //   this.dialogRef.close();}
}

export class ConfirmationModel {
  constructor(public _headerTitle: ConfirmationHeader, public _message: string, public _buttonType: ButtonType = ButtonType.YesNo, _respObject: any = true) {
    this.headerTitle = ConfirmationHeader[_headerTitle].toString();
    this.message = _message;
    this.buttonType = _buttonType;
    this.respObject = _respObject;

    switch (_headerTitle) {
      case ConfirmationHeader.Confirmation:
        this.headerIcon = ' fa fa-question-circle-o';
        this.headerBgColor = ' #a7c5641a ';
        this.fontColor = '#004d40';
        break;
      case ConfirmationHeader.Success:
        this.headerIcon = ' fa fa-check ';
        this.headerBgColor = ' #a7c5641a ';
        this.fontColor = '#004d40';
        break;
      case ConfirmationHeader.Notification:
        this.headerIcon = ' fa fa-exclamation ';
        this.headerBgColor = ' #238AE6 ';
        this.fontColor = 'white';
        break;
      case ConfirmationHeader.Warning:
        this.headerIcon = ' fa fa-exclamation-triangle ';
        this.headerBgColor = '#FF875C';
        this.fontColor = 'white';
        break;
      case ConfirmationHeader.Error:
        // this.headerIcon = 'fa fa-exclamation-circle';
        this.headerIcon = '';
        this.headerBgColor = '#141518';
        this.fontColor = 'white';
        break;
      default:
        this.headerIcon = 'fa fa-exclamation';
        this.headerBgColor = '#a7c5641a';
        this.fontColor = '#004d40';
        break;
    }
  }
  public headerTitle: string;
  public message: string;
  public headerIcon: string;
  public headerBgColor: string;
  public fontColor: string;
  public buttonType: ButtonType;
  public respObject: any;
}

export enum ButtonType {
  Ok = 0,
  OkCancel = 1,
  YesNo = 3,
  OkNoThanks = 1,
  GeneratePDFCancel = 4
}