import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, OnDestroy } from '@angular/core';
import { saveAs } from 'file-saver';
import { isNullOrUndefined } from 'util';
import { Documents } from '../../common-services/models.service';
import { SharedService } from '../../shared/shared.service';
import { MatDialogRef } from '@angular/material';
import { CommonService } from '../../common-services/common-functions.service';

@Component({
  selector: 'app-image-list-preview',
  templateUrl: './image-list-preview.component.html',
  styleUrls: ['./image-list-preview.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImageListPreviewComponent implements OnInit {

  @Input() boxHeight = 500;
  @Input() isPreviewFirst = false;

  imageFileList = new Array<Documents>();
  docFileList = new Array<Documents>();
  pageProperties = { previewImage: null, selectedDoc: null, showProgress: false };
  private imageFormat = ['jpeg', 'jpg', 'png', 'gif', 'tiff'];

  @Input() set docuementList(val: Documents[]) {
    if (val) {
      this.docFileList = this.imageFileList = [];
      this.pageProperties.selectedDoc = null;
      this.imageFileList = this.applyThumbnail(val, 'images');
      this.docFileList = this.applyThumbnail(val, 'files');
      if (this.isPreviewFirst) {
        this.onPreviewImgeClick(val[0]);
      }
    }
  };

  @Input() apiUrl:string = 'common/getfile?filePath=';
  @Output() viwedDocument = new EventEmitter<any>();
  isPopup = false;

  constructor(private sharedService: SharedService, public dialogRef: MatDialogRef<ImageListPreviewComponent>, ) { }
  ngOnInit() { }

  applyThumbnail(resp: any, typeOfList: string): any {
    let extension = '';
    return resp.filter((t) => {
      if (!isNullOrUndefined(t.documentName) && t.documentName.lastIndexOf(".") >= 0) {
        extension = t.documentName.substring(t.documentName.lastIndexOf(".") + 1);
        t.thumbnail = this.getClassName(extension);
        t.fileType = this.getFileType(extension);
      } else {
        t.thumbnail = '/assets/images/nofile.png';
        t.fileType = 'ohter';
        t.fileName = '<span style="color:#b3b3b3">Not Available<span>'
      }
      if (typeOfList == 'images' && this.imageFormat.indexOf(extension) >= 0) {
        return t;
      }
      if (typeOfList == 'files' && this.imageFormat.indexOf(extension) == -1) {
        return t;
      }
    });
  }

  getClassName(fileExtension) {
    switch (fileExtension) {
      case 'xlsx':
        return "/assets/images/xls.png";
      case 'docx':
        return "/assets/images/doc.png";
      case 'jpg':
        return "/assets/images/jpeg.png";
      case 'rar':
        return "/assets/images/zip.png";
      default:
        return '/assets/images/' + fileExtension + '.png';
    }
  }

  private getFileType(extenstion: string) {
    switch (extenstion.toUpperCase()) {
      case "JPG":
      case "JPEG":
      case "PNG":
      case "GIF":
      case "TIFF":
        return "image"
      case "PDF":
        return "pdf"
      default:
        return 'other';
    }
  }

  onPreviewImgeClick(doc) {
    this.pageProperties.showProgress = true;
    this.pageProperties.selectedDoc = CommonService.copyObject(doc);
    this.pageProperties.selectedDoc.hidePreviewButton = true;
    if(doc.objectURL){
      this.pageProperties.previewImage = doc.objectURL
      this.pageProperties.showProgress = false;
    }
    else{
      this.pageProperties.previewImage = doc.documentPath
      this.pageProperties.showProgress = false;
    }
      
    }

  onDownloadFile(doc) {

   var url = doc.documentPath;
   window.open(url, "_blank");

  }
  onCancelClick() {
    this.dialogRef.close();
  }
}
