import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { MatDialogRef, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-barcodehistory',
  templateUrl: './barcodehistory.component.html',
  styleUrls: ['./barcodehistory.component.css']
})
export class BarcodehistoryComponent implements OnInit {

  statusTable:any;
  displayedColumns= ['boxType','count']
  dataSource = new MatTableDataSource<any>([]);
  @Input() batchNo:string ;
  @Input() batchId:number ;
  @Input() productCode:string ;
  @Input() vendorCode:string ;
  constructor(private sharedService: SharedService, public dialogRef: MatDialogRef<BarcodehistoryComponent>, ) { }

  ngOnInit() {
    this.fillData();
  }

  fillData(){
    this.sharedService.customGetApi('asn/barcodeprintcount?vendorCode=' + this.vendorCode + '&productCode=' + this.productCode + '&BatchNo=' + this.batchNo+'&BatchId='+this.batchId).subscribe((t) => {
      if(t){
        if(t.status===true){
          this.dataSource=t.data;
        }
      }
    });
  }
  onCancelClick(){
    this.dialogRef.close();
  }
}
