import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ButtonType, ConfirmationModel, ConfirmationPopupComponent } from '../common-component/confirmation-popup/confirmation-popup.component';
import { AccessLevel, ConfirmationHeader, VendorEnum } from '../common-services/enum.service';
import { IShortCutKeys } from '../common-services/interface.service';
import { ApiResponse, CommonFilter } from '../common-services/models.service';

@Injectable()
export class SharedService {

  sharedMaster = {};
  ddl = { 1: { api: 'state/ddlist', list: new Array<any>() } };
  shortCutKeyListner = new Subject<IShortCutKeys>();
  private _vendorDetail: any;
  private _wareHouseList: any;
  tableConfiguration = {};
  reportConfiguration = {};
  masterUrl = {
    1: { List: 'account/users', 'Save': 'account/create', 'Delete': 'account/delete/', 'Filter': 'payment/filter', 'isExists': '', 'byId': 'account/user/' },
    4: { List: 'vendor/list?search=', byId: 'vendor/bycode?code=' },
    5: { byId: 'po/byid?poid=' }
  };
  pageRouteConfig = {
    21: { pageRoute: 'vp/Portfolio/', retriveField: 'npi_id' },
    24: { pageRoute: 'barcode/barcodepattern/', retriveField: 'id' },
    25: { pageRoute: 'barcode/barcodebatch/', retriveField: 'id' },
    26: { pageRoute: 'barcode/barcodemapping/', retriveField: 'id' },
    28: { pageRoute: 'barcode/barcodepattern/', retriveField: 'id' },
    29: { pageRoute: 'vp/vendorPortfolio/', retriveField: 'vPId' },
    32: { pageRoute: 'vp/vendor/' , retriveField: 'id'}, //MR #8 New Form Redirect
  }

  searchProgress = false;
  printObject = null;
  formBtn = { actionAccess: AccessLevel.None };
  constructor(public dialog: MatDialog, private httpClient: HttpClient) { }

  public isEnableOrVisible(no): boolean {
   
    return (this.formBtn.actionAccess & no) > 0;
  }

  getConfirmatonConfig(header: ConfirmationHeader, message, _buttonType: ButtonType = ButtonType.YesNo, data: any = null) {
    return this.dialog.open(ConfirmationPopupComponent, {
      width: '400px',
      data: new ConfirmationModel(header, message, _buttonType, data),
      disableClose: true,
      closeOnNavigation: true
    });
  }

  public get wareHouseList(): Observable<any[]> {
    if (this._wareHouseList && this._wareHouseList.length > 0) {
      return Observable.of(this._wareHouseList);
    } else {
      return this.customGetApi('asn/warehouselist').map((t) => {
        if (t && t.data) {
          this._wareHouseList = t.data;
        }
        return t.data
      });
    }
  }
  getTableConfiguration(page: any) {
    if (this.tableConfiguration[page] && this.tableConfiguration[page].length > 0) {
      return Observable.of({ data: this.tableConfiguration[page] });
    } else {
      return this.customGetApi('common/tblconfig?type=' + page).map((t) => {
        this.tableConfiguration[page] = t.data;
        return t;
      }).catch(err => Observable.throw(err));
    }
  }

  getReportConfiguration(page: any, vencdorCode) {
    if (this.reportConfiguration[page]) {
      return Observable.of({ data: this.reportConfiguration[page] });
    } else {
      return this.customGetApi('common/reportconfig?type=' + page).map((t) => {
        if (t.data.hasFromToDateField && vencdorCode) {
          const d = new Date();
          d.setDate(d.getDate() - (t.data.defDateDurationInDays > 0 ? t.data.defDateDurationInDays : 30));
          const filter = this.getSearchFilter(page, vencdorCode);
          filter.filterArray.push({ key: t.data.hasFromToDateField + '_from', value: d });
          filter.filterArray.push({ key: t.data.hasFromToDateField + '_to', value: new Date() });
          this.sharedMaster[page].searchFilter = filter;
        }
        this.reportConfiguration[page] = t.data;
        return t;
      }).catch(err => Observable.throw(err));
    }
  }
  getSearchFilter(page: VendorEnum, _vendorCode: string): CommonFilter {
    if (isNullOrUndefined(this.sharedMaster[page])) {
      this.sharedMaster[page] = new FilterObject(new CommonFilter(_vendorCode, page));
    }
    this.sharedMaster[page].searchFilter.vendorCode = _vendorCode;
    return this.sharedMaster[page].searchFilter;
  }

  searchDropdownList(ddlFor, param: string = '') {
    return this.httpClient.get(this.ddl[ddlFor].api + param).map((t: ApiResponse) => t.data);
  }

  filter(master: VendorEnum, param1: any = null, queryParam: any = null): Observable<any> {
    if (!isNullOrUndefined(queryParam)) {
      return this.httpClient.post<ApiResponse>(this.masterUrl[master].Filter + queryParam, param1).map(t => t);
    } else {
      return this.httpClient.post<ApiResponse>(this.masterUrl[master].Filter, param1).map(t => t);
    }
  }

  getFilter(filter: CommonFilter): Observable<any> {
    return this.httpClient.post<any[]>('common/filter', filter).map(t => t);
  }

  getList(master: VendorEnum, param1: any = ''): Observable<any> {
    return this.httpClient.get<any[]>(this.masterUrl[master].List + param1).map(t => t);
  }

  save(master: VendorEnum, param1: any = null, queryParam: any = null): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(this.masterUrl[master].Save, param1).map(t => t);
  }

  isExists(master: VendorEnum, param1: any = null): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(this.masterUrl[master].isExists + param1);
  }

  byId(master: VendorEnum, param1: any = null): Observable<any> {
    return this.httpClient.get<ApiResponse>(this.masterUrl[master].byId + param1).map(t => t);;
  }

  delete(master: VendorEnum, param1: any = null, queryParam: any = null): Observable<any> {
    return this.httpClient.post<any>(this.masterUrl[master].Delete, param1).map(t => t);
  }

  deleteById(master: VendorEnum, param1: any = null, queryParam: any = null): Observable<any> {
    return this.httpClient.get<any>(this.masterUrl[master].Delete + param1).map(t => t);
  }

  customGetApi(api: string): Observable<any> {
    return this.httpClient.get<any>(api).map(t => t);
  }

  customPostApi(api: string, data: any): Observable<any> {
    return this.httpClient.post<any>(api, data).map(t => t);
  }

  previewImage(url: string, filePath: string): Observable<any> {
    if (!isNullOrUndefined(filePath) && filePath !== '') {
      return this.httpClient.get(url + encodeURIComponent(filePath), { responseType: 'blob', observe: 'response' });
    } else {
      return Observable.of(null);
    }
  }

  uploadFile(_file, dataParam: { id: string, docType: number}, url = 'common/upload') {
    if (_file) {
      let formData = new FormData();
      formData.set('content', _file, _file.name);
      let RequestParams = new HttpParams();
      if (dataParam) {
        Object.keys(dataParam).forEach(function (key) {
          RequestParams = RequestParams.append(key, dataParam[key]);
        });
      }
      return this.httpClient.post<any>(url, formData, { params: RequestParams });
    }
  }

  uploadFileS3(_file,dataParam: { filename: string,folderid:number}, url = 'common/upload') {
    if (_file) {
      let formData = new FormData();
      formData.set('ProfileImage', _file, _file.name);
      let RequestParams = new HttpParams();
      if (dataParam) {
        Object.keys(dataParam).forEach(function (key) {
          RequestParams = RequestParams.append(key, dataParam[key]);
        });
      }
      return this.httpClient.post<any>(url, formData, { params: RequestParams });
    }
  }
  uploadProductImages(_file, dataParam: { id: string, docType: number,doctypeId:number,VendorCode:string,ProductCode:string}, url = 'common/uploadproductimages') {
    if (_file) {
      let formData = new FormData();
      formData.set('content', _file, _file.name);
      let RequestParams = new HttpParams();
      if (dataParam) {
        Object.keys(dataParam).forEach(function (key) {
          RequestParams = RequestParams.append(key, dataParam[key]);
        });
      }
      return this.httpClient.post<any>(url, formData, { params: RequestParams });
    }
  }

  uploadFileNew(_file, dataParam: { id: string, docType: number,docNo:string,docTypeId:number}, url = 'common/uploadnew') {
    if (_file) {
      let formData = new FormData();
      formData.set('content', _file, _file.name);
      let RequestParams = new HttpParams();
      if (dataParam) {
        Object.keys(dataParam).forEach(function (key) {
          RequestParams = RequestParams.append(key, dataParam[key]);
        });
      }
      return this.httpClient.post<any>(url, formData, { params: RequestParams });
    }
  }
}

export class FilterObject {
  constructor(private _searchFilter: any = null, private _dataSource: any = null) {
    this.searchFilter = isNullOrUndefined(_searchFilter) ? new CommonFilter('') : _searchFilter;
    this.dataSource = _dataSource;
  }
  searchFilter: CommonFilter;
  dataSource = [];
}

// let expenseName = '';
    // if (!isNullOrUndefined(expenseID) && expenseID > 0) {
    //   const list: any = this.getDropdownList(MastersEnum.Expense);
    //   if (!isNullOrUndefined(list) && list.value.length > 0) {
    //     list.value.filter((e) => {
    //       if (e.id == expenseID) {
    //         expenseName = e.name;
    //       }
    //     });
    //   }
    // }
    // return expenseName;
    // getDropdownList(ddlFor) {
  //   const obj = this.ddl[ddlFor];
  //   if (!isNullOrUndefined(obj)) {
  //     if (!isNullOrUndefined(obj.list) && obj.list.length > 0) {
  //       return Observable.of(obj.list);
  //     } else {
  //       return this.httpClient.get(obj.api).map((t: any) => {
  //         if (t.status === true) {
  //           this.ddl[ddlFor].list = t.data;
  //           return t.data;
  //         } else {
  //           return Observable.throw(t.message);
  //         }
  //       });
  //     }
  //   } else {
  //     Logger.Warn(`getDropdownList Not Exists ${ddlFor}`);
  //     return Observable.of(null);
  //   }
  // }

  // getDropdownName(id: number, pageNo: VendorEnum, matchkey = 'id', resultKey = 'name') {
  //   let name = '';
  //   if (!isNullOrUndefined(id) && id > 0) {
  //     const list: any = this.getDropdownList(pageNo);
  //     if (!isNullOrUndefined(list) && list.value.length > 0) {
  //       list.value.filter((e) => {
  //         if (e[matchkey] == id) {
  //           name = e[resultKey];
  //         }
  //       });
  //     }
  //   }
  //   return name;
  // }