import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatExpansionModule, MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatSnackBarModule, MatSortModule, MatTableModule, MatTabsModule, MatTooltipModule, MatTreeModule, MatStepperModule, MatBadgeModule, MatRippleModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CustomToolTipComponent } from '../common-component/custom-tool-tip/custom-tool-tip.component';
import { FileListviewComponent } from '../common-component/file-upload/file-listview/file-listview.component';
import { FileUploadComponent } from '../common-component/file-upload/file-upload.component';
import { FormButtonsComponent } from '../common-component/form-buttons/form-buttons.component';
import { ImageListPreviewComponent } from '../common-component/image-list-preview/image-list-preview.component';
import { MatTableComponent } from '../common-component/mat-table/mat-table.component';
import { BytesPipe } from './byte.pipe';
import { HoverClassDirective } from './hover-class.directrive';
import { NumberToWordsPipe } from './number-to-words.pipe';
import { SafePipe } from './safe-resource.pipe';
import { ToolTipRendererDirective } from './tool-tip-renderer.directive';
import { ChangeHistoryComponent } from '../common-component/change-history/change-history.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChangeDeliverydateComponent } from '../cpos-modules/vendor/advance-shipment-note/change-deliverydate/change-deliverydate.component';
import { TwoDigitDecimaNumberDirective } from './two-digit-decima-number.directive';
import { BarcodehistoryComponent } from '../common-component/barcodehistory/barcodehistory.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatListModule,
    MatFormFieldModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatGridListModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSelectModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTreeModule,
    MatDialogModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSortModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTabsModule,
    MatStepperModule,
    DragDropModule,
    MatBadgeModule,
    MatRippleModule,
    NgxMatSelectSearchModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatListModule,
    MatFormFieldModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatGridListModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSelectModule,
    MatMomentDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTreeModule,
    MatDialogModule,
    MatProgressBarModule,
    MatTableComponent,
    MatTooltipModule,
    FormButtonsComponent,
    MatSortModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    NumberToWordsPipe,
    MatChipsModule,
    MatTabsModule,
    MatStepperModule,
    SafePipe,
    BytesPipe,
    HoverClassDirective,
    ToolTipRendererDirective,
    FileUploadComponent,
    FileListviewComponent,
    ImageListPreviewComponent,
    CustomToolTipComponent,
    ChangeHistoryComponent,
    DragDropModule,
    MatBadgeModule,
    MatRippleModule,
    TwoDigitDecimaNumberDirective,
    BarcodehistoryComponent,
    NgxMatSelectSearchModule
  ],
  declarations: [
    MatTableComponent,
    FileUploadComponent,
    FormButtonsComponent,
    NumberToWordsPipe,
    HoverClassDirective,
    SafePipe,
    BytesPipe,
    FileListviewComponent,
    ImageListPreviewComponent,
    CustomToolTipComponent,
    ToolTipRendererDirective,
    ChangeHistoryComponent,
    ChangeDeliverydateComponent,
    TwoDigitDecimaNumberDirective,
    BarcodehistoryComponent
  ],
  entryComponents: [CustomToolTipComponent, ChangeHistoryComponent, ImageListPreviewComponent,ChangeDeliverydateComponent,BarcodehistoryComponent]
})
export class SharedModule { }
