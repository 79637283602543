import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { SharedService } from '../../../../shared/shared.service';
import { AuthService } from '../../../../auth/auth.service';
import { IndianDateadapterService, MY_DATE_FORMATS } from '../../../../common-services/indian-dateadapter.service';
import { FormBuilder } from '@angular/forms';
import { SnackBarService } from '../../../../common-services/snack-bar.service';
import { DateService } from '../../../../common-services/common-functions.service';
import { isNullOrUndefined } from 'util';
import { ButtonType } from '../../../../common-component/confirmation-popup/confirmation-popup.component';
import { ConfirmationHeader } from '../../../../common-services/enum.service';

@Component({
  selector: 'app-change-deliverydate',
  templateUrl: './change-deliverydate.component.html',
  styleUrls: ['./change-deliverydate.component.css'],
  providers: [
    { provide: DateAdapter, useClass: IndianDateadapterService },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
})
export class ChangeDeliverydateComponent implements OnInit {
  DateChangeReason: string;
  isVendor: boolean;
  DeliveryDate: any;
  acceptdialogConfirmation: any;
  acceptsdialogConfirmation: any;
  userRole = '';
  canASNBPPIApproval: boolean;
  allowToChange = false; // BPPI User 
  allowToChangeForVenderUser = true; // Vendor User
  bppiRemark: string;
  SelectedASN: any;
  BppiStatus: string;
  showDeliveryDate:boolean;
  //AllowedToChangeDate:boolean;
  //@Inject(MAT_DIALOG_DATA) public data: any,
  constructor(public dialogRef: MatDialogRef<ChangeDeliverydateComponent>,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snacknbar: SnackBarService,
  ) {
    this.SelectedASN = data.header;
    this.isVendor = this.authService.UserRole == 'Vendor';
    this.allowToChangeForVenderUser = this.isVendor;
    this.userRole = authService.UserRole;
    this.canASNBPPIApproval = this.authService.getAppoval.canASNBPPIApproval;
    
    if(!isNullOrUndefined(this.SelectedASN.asnDeliverychangeDate)){
      this.DeliveryDate = new Date(this.SelectedASN.asnDeliverychangeDate);
    }
    this.DateChangeReason = this.SelectedASN.asnDeliverychangereason;
    if(this.isVendor)
      this.allowToChange = false;
    else
     this.allowToChange = true;
    //this.AllowedToChangeDate=this.SelectedASN.canChangeDelDate;
    this.bppiRemark = this.SelectedASN.delChangeReqReamrks;
    this.BppiStatus = this.SelectedASN.delChnageReqStatus;
    if (!isNullOrUndefined(this.BppiStatus)) {
      if (this.BppiStatus == 'Accept') {
        this.allowToChangeForVenderUser = false;
        this.allowToChange = false;
      }
    }

    if(!this.isVendor)
    {
      this.showDeliveryDate=true;
    }
    else if(this.isVendor && (!isNullOrUndefined(this.BppiStatus) && this.BppiStatus!='' ))
    {
      this.showDeliveryDate=true;
    }
  }

  ngOnInit() {
  }
  onCancelClick() {
    this.dialogRef.close();
  }
  onDateChange() {

  }

  onSave() {
    if (this.DateChangeReason) {
      this.sharedService.customPostApi('asn/changedeliverydaterequest', {  dateChangeReason: this.DateChangeReason, asnId: this.SelectedASN.asnId }).subscribe((t) => {
        if(t.status==true)
        {
          
        this.dialogRef.componentInstance;
        this.snacknbar.success('Record saved successfully');
        this.dialogRef.close(true);
        }
        else
        {
          this.snacknbar.error(t.message);
        }
      })

    }
    else {
      this.snacknbar.warning('Please Enter Reason');
    }
  }

  HandleStautRequest(status: string) {
    if (status) {
      this.sharedService.customPostApi('asn/changedeliverydatestatus', { asnDeliveryDate: DateService.getUTCDate(this.DeliveryDate), dateChangeReason: this.DateChangeReason,dateChangeReqStatus: status, requestRemarks: this.bppiRemark, asnId: this.SelectedASN.asnId }).subscribe((t) => {
        this.dialogRef.componentInstance;
        this.BppiStatus = status;
        this.snacknbar.success('Record saved successfully');
        this.dialogRef.close(true);
      })
    }

  }

  onApproveOrReject(isApprove) {
      if (isApprove == true) {
          if (this.DeliveryDate) {
              this.acceptdialogConfirmation = this.sharedService.getConfirmatonConfig(ConfirmationHeader.Confirmation, 'Are you sure want to proceed?', ButtonType.YesNo, isApprove);
              this.acceptdialogConfirmation.afterClosed().subscribe(result => {
                  if (!isNullOrUndefined(result)) {
                      this.HandleStautRequest("Accept");
                  }
              });
          }
          else {
              this.snacknbar.error('Please Enter Delivery Date');
          }

    }
    else {
      if (this.bppiRemark) {

        this.acceptsdialogConfirmation = this.sharedService.getConfirmatonConfig(ConfirmationHeader.Confirmation, 'Are you sure want to proceed?', ButtonType.YesNo, isApprove);
      this.acceptsdialogConfirmation.afterClosed().subscribe(result => {
        if (!isNullOrUndefined(result)) {
          this.HandleStautRequest("Reject");
        }
      });

        
      }
      else {
        this.snacknbar.error('Please Enter Reason');
      }
    }
  }
  ngOnDestroy(): void { 
   
    if (this.acceptdialogConfirmation) {
      this.acceptdialogConfirmation.close();
    }
    if (this.acceptsdialogConfirmation) {
      this.acceptsdialogConfirmation.close();
    }
  }
}
