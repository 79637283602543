import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, Inject } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SharedService } from '../../shared/shared.service';
import { ApiResponse } from '../../common-services/models.service';
import { CommonService } from '../../common-services/common-functions.service';

@Component({
  selector: 'app-change-history',
  templateUrl: './change-history.component.html',
  styleUrls: ['./change-history.component.css']
})
export class ChangeHistoryComponent implements OnInit {

  treeControl: FlatTreeControl<HistoryHeader>;
  treeFlattener: MatTreeFlattener<HistoryDetail, HistoryHeader>;
  dataSource: MatTreeFlatDataSource<HistoryDetail, HistoryHeader>;
  heightPopup = '250px';
  tableHeight = 500;
  constructor(public dialogRef: MatDialogRef<ChangeHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public history: any, private sharedService: SharedService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
      this._isExpandable, this._getChildren);
    this.treeControl = new FlatTreeControl<HistoryHeader>(this._getLevel, this._isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.sharedService.customGetApi("common/history?tableName=" + history.tableName + "&id=" + history.id).subscribe((t: ApiResponse) => {
      this.dataSource.data = t.data;
    });
  }
  private _getLevel = (node: HistoryHeader) => node.level;

  private _isExpandable = (node: HistoryHeader) => node.expandable;

  private _getChildren = (node: HistoryDetail): HistoryDetail[] => node.children;

  hasChild = (_: number, _nodeData: HistoryHeader) => _nodeData.expandable;

  transformer = (node: HistoryDetail, level: number) => {
    return new HistoryHeader(!!node.children, level, node.updatedBy, node.updatedOn, node.updateSince, this.getobject(node.jsonStr));
  }

  ngOnInit() {
    // this.sharedService.customGetApi("common/history?tableName=POHeader&id=1").subscribe((t: ApiResponse) => {
    //   this.dataSource.data = t.data;
    // });
    const digit = this.dialogRef._containerInstance._config.height.replace('px', '');
    this.heightPopup = (Number(digit) - 140).toString() + 'px';
    this.tableHeight = CommonService.mainSectionLW.height - 340;
  }

  getobject(str) {
    if (str) {
      return JSON.parse(str);
    } else {
      return [];
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }
}

export class HistoryHeader {
  constructor(
    public expandable: boolean,
    public level: number,
    public updatedBy: string,
    public updatedOn: string,
    public updateSince: string,
    public JsonArr: []
  ) { }
}
export class HistoryDetail {
  constructor() {
    // this.children = new Array<HistoryDetail>();
    this.children = new Array<any>();
  }
  // children: Array<HistoryDetail>;
  children: Array<any>;
  priKey: string;
  updatedBy: string;
  updatedOn: string;
  updateSince: string;
  jsonStr: string;
}

// let asdf = new HistoryDetail();
    // asdf.updatedBy = 'Parimal Vaghasiya';
    // asdf.updateSince = '3 Months ago';
    // asdf.children.push(
    //   {
    //     jsonStr: '[{"PropertyName":"ApprovalReason","PrimaryKey":1,"OldValue":"test 1234","NewValue":"test 123","UpdatedBy":1},{"PropertyName":"UpdatedOn","PrimaryKey":1,"OldValue":"09-08-2019 16:34:32","NewValue":"01-01-0001 00:00:00","UpdatedBy":1}]', priKey: '1'
    //   });
        // this.dataSource.data = [asdf];
