import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TimeoutComponent implements OnInit {

  public timeCount;
  public totalCount = 120;
  constructor(public dialogRef: MatDialogRef<TimeoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() { }

  public get progress(): number {
    return Math.round((this.timeCount / this.totalCount) * 100);
  }

  public get getColor(): string {
    return this.progress > 90 ? 'warn' : this.progress > 50 ? 'accent' : 'primary';
  }

  onClick(status) {
    this.dialogRef.close(status);
  }
}
