import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { UserIdleModule } from 'angular-user-idle';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { environment } from '../environments/environment';
import { AppInitialize } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { TimeoutComponent } from './auth/timeout/timeout.component';
import { ConfirmationPopupComponent } from './common-component/confirmation-popup/confirmation-popup.component';
import { FilterGridComponent } from './common-component/filter-grid/filter-grid.component';
import { ExportSelectionComponent } from './common-component/mat-table/export-selection/export-selection.component';
import { SubReportComponent } from './common-component/mat-table/sub-report/sub-report.component';
import { SnackbarComponentComponent } from './common-component/snackbar-component/snackbar-component.component';
import { HttpInterceptorService } from './common-services/http-interceptor.service';
import { IndianDateadapterService, MY_DATE_FORMATS } from './common-services/indian-dateadapter.service';
import { HistoryService, SnackBarService } from './common-services/snack-bar.service';
import { HomeComponent } from './cpos-modules/home/home.component';
import { MenuListItemComponent } from './menu/app-menu-list-item/menu-list-item.component';
import { MenuItemComponent } from './menu/menu-item/menu-item.component';
import { NavService } from './menu/menu-service/nav.service';
import { MenuComponent } from './menu/menu.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { SharedModule } from './shared/shared.module';
import { SharedService } from './shared/shared.service';
import { NgOtpInputModule } from 'ng-otp-input';


export function get_settings(appInitService: AppInitialize) {
  return () => appInitService.getSettings(environment.congifURL);
}

@NgModule({
  declarations: [
    AppComponent,
    NotfoundComponent,
    MenuListItemComponent,
    MenuItemComponent,
    MenuComponent,
    SnackbarComponentComponent,
    ConfirmationPopupComponent,
    HomeComponent,
    TimeoutComponent,
    FilterGridComponent,
    ExportSelectionComponent,
    SubReportComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    NgProgressModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgProgressRouterModule,
    KeyboardShortcutsModule,
    NgOtpInputModule,
    ScrollToModule.forRoot(),
    UserIdleModule.forRoot({ idle: 150, timeout: 60, ping: 120 }),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [NavService, AppInitialize, AuthGuard, AuthService, SnackBarService, SharedService, HistoryService,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: APP_INITIALIZER, useFactory: get_settings, deps: [AppInitialize], multi: true },
    { provide: DateAdapter, useClass: IndianDateadapterService },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
  entryComponents: [SnackbarComponentComponent, ConfirmationPopupComponent, TimeoutComponent, FilterGridComponent, ExportSelectionComponent, SubReportComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
