import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  // sliderHeight = '500px';
  
  public get isLogin() : boolean {
    return this.authService.isAuthenticated;
  }
  
  constructor(private authService: AuthService) {
    // this.sliderHeight = String(screen.height - 200) + 'px';
  }

  ngOnInit() {
  }

}
