import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import { FileUploadComponent } from '../file-upload.component';

@Component({
  selector: 'app-file-listview',
  templateUrl: './file-listview.component.html',
  styleUrls: ['./file-listview.component.css']
})
export class FileListviewComponent implements OnInit {

  public isUploading: boolean = false;
  /** Output  */
  @Output() removeEvent = new EventEmitter<number>();
  @Output() onUpload = new EventEmitter();
  errorMessage: string;
  @Input() set isUploadAll(val: boolean) {
    if (val) {
      this.upload();
    }
  }
  @Input() set isRemoveAll(val: boolean) {
    if (val) {
      this.upload();
    }
  }

  public progressPercentage: number = 0;
  public loaded: number = 0;
  public total: number = 0;
  private _file: any;
  private _id: number;
  private fileUploadSubscription: any;

  private httpUrl: string;
  // /multipart
  private httpRequestHeaders: HttpHeaders | {
    [header: string]: string | string[];
  } = new HttpHeaders().set("Content-Type", "multipart/form-data");

  private requestObject: any
  // private httpRequestParams: HttpParams | {
  //   [param: string]: string | string[];
  // } = new HttpParams();
  private fileAlias: string;

  @Input() get file(): any {
    return this._file;
  }

  set file(file: any) {
    this._file = file;
    this.total = this._file.size;
  }

  @Input() set id(id: number) {
    this._id = id;
  }

  get id(): number {
    return this._id;
  }

  constructor(private httpClient: HttpClient, @Inject(forwardRef(() => FileUploadComponent)) public matFileUploadQueue: FileUploadComponent) {
    if (matFileUploadQueue) {
      this.httpUrl = matFileUploadQueue.httpUrl;
      this.httpRequestHeaders = matFileUploadQueue.httpRequestHeaders || this.httpRequestHeaders;
      this.requestObject = matFileUploadQueue.requestObject;
      this.fileAlias = matFileUploadQueue.fileAlias || this.fileAlias;
    }
  }

  ngOnInit() {
  }

  public upload(): void {
    try {
      this.isUploading = true;
      // How to set the alias?
      let formData = new FormData();
      // formData.set(this.fileAlias, this._file, this._file.name);
      formData.set('content', this._file, this._file.name);
      this.errorMessage = '';
      const dataParam = this.requestObject;
      let RequestParams = new HttpParams();
      if (dataParam) {
        Object.keys(dataParam).forEach(function (key) {
          RequestParams = RequestParams.append(key, dataParam[key]);
        });
      }

      this.fileUploadSubscription = this.httpClient.post<any>(this.httpUrl, formData, {
        observe: "events",
        params: RequestParams,
        reportProgress: true,
      }).subscribe((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressPercentage = Math.floor(event.loaded * 100 / event.total);
          this.loaded = event.loaded;
          this.total = event.total;
        }
        if (event && event.status && event.body && event.status == 201) {
          this.onUpload.emit({ file: this._file, document: event.body });
          setTimeout(() => {
            this.remove();
          }, 2000);
        }
      }, (error: any) => {
        if (this.fileUploadSubscription) {
          this.fileUploadSubscription.unsubscribe();
        }
        this.isUploading = false;
        this.errorMessage = error.error===''?'File Upload Failed':error.error;
        this.progressPercentage = 0;
        // this.onUpload.emit({ file: this._file, event: event });
      });
    } catch (error) {
      this.isUploading = false;
    }
  }

  public remove(): void {
    if (this.fileUploadSubscription) {
      this.fileUploadSubscription.unsubscribe();
    }
    this.removeEvent.emit(this.id);
  }

  ngOnDestroy() {
    console.log('file ' + this._file.name + ' destryed...');
  }

}
