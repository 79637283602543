import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavItem, NavService } from '../menu-service/nav.service';


@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.css'],
    animations: [
        trigger('indicatorRotate', [
            state('collapsed', style({ transform: 'rotate(0deg)' })),
            state('expanded', style({ transform: 'rotate(180deg)' })),
            transition('expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
            ),
        ])
    ]
})
export class MenuItemComponent implements OnInit {
    @Input() item: NavItem;
    @ViewChild('childMenu', { static: true }) public childMenu;
    @Input() depth: number;
    expanded: boolean;
    @HostBinding('attr.aria-expanded') ariaExpanded;
    currentUrl = '';

    constructor(public navService: NavService, public router: Router) {

        this.ariaExpanded = this.expanded
        if (this.depth === undefined) {
            this.depth = 0;
        }
    }

    ngOnInit() {
        this.navService.currentUrl.subscribe((url: string) => {
            this.currentUrl = url;
            if (this.item.link && url) {
                // console.log(`Checking '/${this.item.route}' against '${url}'`);
                this.expanded = url.indexOf(`${this.item.link}`) === 0;
                this.ariaExpanded = this.expanded;
                // console.log(`${this.item.route} is expanded: ${this.expanded}`);
            }
        });
    }

    onItemSelected(item: NavItem) {
        // this.item = item;
        if (!item.children || !item.children.length) {
            this.router.navigate([item.link]);
            if (this.navService.restrictClose === false) {
                document.getElementById('btnToggle').click();
            }
        }
        if (item.children && item.children.length) {
            this.expanded = !this.expanded;
        }
    }
}
