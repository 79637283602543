import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-export-selection',
  templateUrl: './export-selection.component.html',
  styleUrls: ['./export-selection.component.css']
})
export class ExportSelectionComponent implements OnInit {

  allField = [];
  selectedFields = [];
  boxHeight = 0;
  constructor(public dialogRef: MatDialogRef<ExportSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) private confModel: any) {
    if (confModel) {
      this.allField = confModel.source.filter((t) => t.isDisplay == false);
      this.selectedFields = confModel.source.filter((t) => t.isDisplay == true);
      this.boxHeight = confModel.height;
    }
  }

  ngOnInit() {

  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onOkClick() {
    if (this.selectedFields.length > 0) {
      this.dialogRef.close(this.selectedFields);
    } else {
      // Message
    }
  }

}
