
export class EnumService {

}
export enum VendorEnum {
    None = 0,
    User = 1,
    UserRegistration = 2,
    UserRights = 3,
    Vendor = 4,
    POApproval = 5,
    DeliverySupplyPlanning = 6,
    AdvanceShipmentNote = 7,
    DeliveryDetailList = 8,
    ProductPortfolio=21,
    BarcodePatternMaster=28,
    BarcodeBatchMaster=25,
    BarcodePatternProductMaping=26,
    BarcodePrint=27,
    VendortPortfolio=29,
    verifybarcode = 30,
    vendorregister = 31
}

export enum AccessLevel {
    ViewHistory = -5,
    Back = -4,
    Discard = -3,
    SaveAndNew = -2,
    Save = -1,
    None = 0,
    View = 1,
    New = 2,
    Edit = 4,
    Delete = 8,
    Print = 16,
    Export = 32,
    Send = 64
}

export enum InputType {
    None = 0,
    Text = 1,
    Email = 2,
    Phone = 3,
    Mobile = 4,
    Number = 5,
    Date = 6,
    IsActive = 7,
    Amount = 8,
    Dropdown = 9,
    HTML = 10,
    Index = 11,
    DateTime = 12,
    SubReport = 13,
    MMMYYYY=14
}


export enum DocumentType {
    None = 0,
    POSignCopy = 1,
    ASNDetailDrugQC = 2,
    LRCopy = 3,
    EWayBillCopy = 4,
    InvoiceCopy = 5,
    BPPIUser = 6,
    VendorUser = 7,
    PerformaInvoice = 8,
    Barcode = 9,
    ArtWork= 10,
    MFGLIC1=11,
    MFGLIC2=12,
    SampleImages=13,
    ProductImages=14
}

// ActionField = 9

export enum ShortCutKeys {
    None = -1,
    Alt0 = 0,
    Alt1 = 1,
    Alt2 = 2,
    Alt3 = 3,
    Alt4 = 4,
    Alt5 = 5,
    Alt6 = 6,
    Alt7 = 7,
    Alt8 = 8,
    Alt9 = 9,
    AltS = 10,
    AltF = 11,
    AltC = 12,
    PageUp = 13,
    PageDown = 14,
    Home = 15,
    End = 16,
    Delete = 17,
    AltA = 18,
    AltR = 19,
    AltX = 20,
    Enter = 21,
}

export enum KEY_CODE {
    ENTER = 13,
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37,
    PAGE_UP = 33,
    PAGE_DOWN = 34,
    END = 35,
    HOME = 36,
    DELETE = 46
}


export enum ConfirmationHeader {
    Confirmation,
    Warning,
    Notification,
    Success,
    Error,
}