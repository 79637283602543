import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CommonService, DateService } from '../../common-services/common-functions.service';
import { InputType, ShortCutKeys, VendorEnum } from '../../common-services/enum.service';
import { IFilterGridInput, IHashTable, IShortCutKeys } from '../../common-services/interface.service';
import { SharedService } from '../../shared/shared.service';

import { IndianDateadapterService, MY_DATE_FORMATS } from '../../common-services/indian-dateadapter.service';
import { cellDef } from '../../common-services/models.service';


@Component({
  selector: 'app-filter-grid',
  templateUrl: './filter-grid.component.html',
  styleUrls: ['./filter-grid.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: IndianDateadapterService },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
})
export class FilterGridComponent implements OnInit, OnDestroy {
  searchData: IFilterGridInput;
  filterForm: FormGroup;
  headerName = '';
  private watcher: Subscription;
  constructor(public dialogRef: MatDialogRef<FilterGridComponent>,
    @Inject(MAT_DIALOG_DATA) private confModel: IFilterGridInput, private fg: FormBuilder, private sharedService: SharedService) {
    // this.searchData = CommonService.copyObject(confModel);
    this.searchData = Object.assign({}, confModel);
    this.filterForm = fg.group({});
    this.generateFormGroup(confModel.searchCell);
    this.headerName = this.setHeaderName(confModel.pageEnum);
    CommonService.focusControlById(confModel.focusControl, 650);
  }

  ngOnInit() {
    this.watcher = this.sharedService.shortCutKeyListner.subscribe((t: IShortCutKeys) => {
      this.shortcutKeyEvent(t);
    });
  }
  shortcutKeyEvent(t: IShortCutKeys) {
    switch (t.key) {
      case ShortCutKeys.AltR:
        this.onResetClick();
        break;
      case ShortCutKeys.AltX:
        this.onCancelClick();
        break;
      case ShortCutKeys.Enter:
        this.onOkClick();
        break;
      default:
        break;
    }
  }

  private generateFormGroup(searchCell) {
    searchCell.forEach((t: cellDef) => {
      switch (t.fieldType) {
        case InputType.Date:
        case InputType.DateTime:
          this.filterForm.addControl(t.displayName + '_from', new FormControl(this.getValue(t.displayName, t.fieldType)));
          this.filterForm.addControl(t.displayName + '_to', new FormControl(this.getValue(t.displayName, t.fieldType)));
          break;
        case InputType.Email:
        case InputType.Text:
        case InputType.SubReport:
          this.filterForm.addControl(t.displayName, new FormControl(this.getValue(t.displayName, t.fieldType)));
          break;
        case InputType.Mobile:
        case InputType.Number:
        case InputType.Phone:
          this.filterForm.addControl(t.displayName, new FormControl(this.getValue(t.displayName, t.fieldType), [Validators.pattern(CommonService.regExPatern.number)]));
          break;
        default:
          break;
      }
    });
  }

  setHeaderName(pageEnum: any) {
    if (this.sharedService.reportConfiguration[pageEnum]) {
      return this.sharedService.reportConfiguration[pageEnum].reportName;
    } else if (VendorEnum[pageEnum]) {
      if(pageEnum===VendorEnum.DeliverySupplyPlanning){
        return 'Delivery Planning List';
      } else{
        return VendorEnum[pageEnum].toString()
      }
      
    } else {
      return '';
    }

  }

  getValue(controlName, fieldType = 0) {
    if (!isNullOrUndefined(this.searchData.prevSearch) && this.searchData.prevSearch.length > 0) {
      let name = null;
      this.searchData.prevSearch.forEach((t: IHashTable) => {
        if (t.key === controlName && !isNullOrUndefined(t.value) && t.value !== '') {
          if (fieldType == InputType.Date || fieldType == InputType.DateTime) {
            name = new Date(t.value)
          } else {
            name = t.value
          }
        }
      });
      return name;
    }
  }

  onOkClick() {
    const searchObj = new Array<IHashTable>();
    let obj: IHashTable = null;
    let dispName;
    if (this.filterForm.valid) {
      this.searchData.searchCell.forEach((t) => {
        dispName = (t.fieldType === InputType.Date || t.fieldType === InputType.DateTime) ? t.displayName + '_from' : t.displayName;
        if (!isNullOrUndefined(this.filterForm.controls[dispName]) && !isNullOrUndefined(this.filterForm.controls[dispName].value) && this.filterForm.controls[dispName].value !== '') {
          switch (t.fieldType) {
            case InputType.Date:
            case InputType.DateTime:
              searchObj.push({ key: dispName, value: DateService.getUTCDate(this.filterForm.controls[dispName].value) });
              searchObj.push({ key: t.displayName + '_to', value: DateService.getUTCDate(this.filterForm.controls[t.displayName + '_to'].value) });
              break;
            default:
              searchObj.push({ key: dispName, value: this.filterForm.controls[dispName].value });
              break;
          }
        }
      });
    }
    this.dialogRef.close(searchObj);
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onResetClick() {
    this.filterForm.reset();
  }

  ngOnDestroy(): void {
    if (!isNullOrUndefined(this.watcher)) {
      this.watcher.unsubscribe();
    }
  }

}
