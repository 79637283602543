import { Component, ContentChild, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Logger } from '../common-services/common-functions.service';
import { SharedService } from '../shared/shared.service';
import { NavItem, NavService } from './menu-service/nav.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {

  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  stickClass = 'leak_add';
  menuSearch = new FormControl();
  pageArray = {
    companyList: []
  }
  screenHeight = 0;
  @ContentChild('appDrawer', { static: false }) appDrawer: ElementRef;
  watcher: Subscription;
  userFullName;
  navItemsCopy = [];
  previewImage: string | ArrayBuffer = '/assets/images/profile.jpg';
  constructor(media: MediaObserver,
    private navService: NavService,
    private authService: AuthService,
    public sharedService: SharedService,
    public route: Router) {
    this.watcher = media.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
      } else {
        this.opened = true;
        this.over = 'side';
      }
    });
    // this.userFullName = authService.UserRole + ': ' + authService.getFullName;
    this.userFullName = authService.getFullName;
    this.loadDefaultData();
    this.userImageFile(this.authService.UserImage);
    this.screenHeight = screen.height;
  }

  navItems: NavItem[] = [
    {
      title: 'Dashboard',
      icon: 'dashboard',
      link: '',
    },
    {
      title: 'Admin',
      icon: 'security',
      children: [
        {
          title: 'Users',
          icon: 'supervised_user_circle',
          link: '/cpos/admin/list',
        },
        {
          title: 'Roles Right',
          icon: 'layers',
          link: '/cpos/admin/rights/1',
        },
        {
          title: 'Users Right',
          icon: 'layers',
          link: '/cpos/admin/rights/2',
        }
      ]
    },
    {
      title: 'Master',
      icon: 'blur_on',
      children: [
        {
          title: 'State',
          icon: 'list_alt',
          link: '/cpos/master/state',
        },
        {
          title: 'City',
          icon: 'list_alt',
          link: '/cpos/master/city',
        },
        {
          title: 'Company',
          icon: 'business',
          link: '/cpos/master/company',
        },
        {
          title: 'Destination',
          icon: 'location_on',
          link: '/cpos/master/destination',
        },
        {
          title: 'Expense',
          icon: 'list_alt',
          link: '/cpos/master/expense',
        },
      ]
    },
  ];

  ngOnInit() {
    Logger.Normal(this.authService.getUserMenu);
    this.navItems = this.authService.getUserMenu as NavItem[];
    this.navItemsCopy = this.authService.getUserMenu as NavItem[];
    this.stickClass = (!this.navService.restrictClose) ? 'leak_add' : 'leak_remove'

    // Menu search at Left Side : Karan 04072024 : #9 
    this.menuSearch.valueChanges.pipe(debounceTime(200)).subscribe((searchvalue) => {
      const filterMenuList = JSON.parse(JSON.stringify(this.navItemsCopy))
      this.navItems = filterMenuList.filter(f => {
        if (f.title.toLowerCase().includes(searchvalue.toLowerCase()) || (f.children && f.children.some(c => c.title.toLowerCase().includes(searchvalue.toLowerCase())))) {
          if (f.children && searchvalue) {
            f.children = f.children.filter(c => c.title.toLowerCase().includes(searchvalue.toLowerCase()));
          }
          return f;
        }
      });
    });
  }

  loadDefaultData(): any {

  }
  userImageFile(filePath: string) {
    if (filePath) {
      this.sharedService.previewImage('common/getfile?filePath=', filePath).subscribe((t) => {
        if (t != null) {
          this.createImageFromBlob(t.body);
        }
      });
    }
  }

  createImageFromBlob(image: Blob) {
    try {
      let reader = new FileReader();
      reader.addEventListener("load", () => {
        if (reader && reader.result) {
          this.previewImage = reader.result;
        }
      }, false);
      if (image) {
        reader.readAsDataURL(image);
      }
    } catch (error) {
      this.previewImage = '/assets/images/profile.jpg';
    }
  }

  onStickSideNavClick() {
    if (this.stickClass === 'leak_add') {
      this.stickClass = 'leak_remove'
      this.navService.restrictClose = true;
    } else {
      this.stickClass = 'leak_add'
      this.navService.restrictClose = false;
    }
  }
  LogOut() {
    this.authService.logOut();
  }
  RedirectToMyAccount() {
    if (this.authService.VendorCode) {
      this.route.navigate(['/master/vendorprofile']);
    }
    else {
      this.route.navigate(['/master/profile']);
    }

  }
}
