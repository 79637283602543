import { isNullOrUndefined, isDate } from "util";
import * as moment from 'moment';

export class DateService {

    public static getUTCDate(dt: any): any {
        try {
            var utc = moment(dt, "YYYY-MM-DDTHH:mm:ssZ", true);
            var isUTC = utc.isValid();
            if (!isUTC) {
                if ((dt) && isDate(dt)) {
                    var m = moment(dt);
                    return m.utcOffset("+05:30").format();
                }
            } else {
                return utc.utcOffset("+05:30").format();
            }
        } catch (error) {
            Logger.Error(error);
            return dt;
        }
    }
    // MR_#8_Add_New Common function Date Format Start..
    public static getDateFormate(dt: any, format = 'YYYY-MM-DD') {
        try {
            let d = new Date(dt);
            return moment(d).format(format);
        } catch (error) {
            Logger.Error(error);
            return dt;
        }
    }
    // MR_#8_Add_New Common function Date Format END..
    public static formatDate(dt: any, format = 'DD/MM/YYYY') {
        try {
            let d = new Date(dt);
            return moment(d).format(format);
        } catch (error) {
            Logger.Error(error);
            return dt;
        }
    }
}

export class CommonService {

    public static getColumnAsArray(arrObj, columnName: string) {
        if ((arrObj) && columnName !== '') {
            return arrObj.map(a => a[columnName]);
        } else {
            return [];
        }
    }
    public static getMatColumnAsArray(arrObj, columnName: string) {
        if ((arrObj) && columnName !== '') {
            return arrObj.filter((t) => t.isDisplay == true).map(a => a[columnName]);
        } else {
            return [];
        }
    }
    public static getControlSizeById(controlId: string): any {
        if ((controlId) && controlId !== '') {
            if ((document.getElementById(controlId))) {
                return { height: document.getElementById(controlId).offsetHeight, width: document.getElementById(controlId).offsetWidth }
            } else {
                return { height: 0, width: 0 }
            }
        } else {
            return { height: 200, width: 200 }
        }
    }

    public static get mainSectionLW() {
        return this.getControlSizeById('mainSection');
    }

    public static focusControlById(controlId: string, time = 100) {
        if ((controlId) && controlId !== '') {
            setTimeout(() => {
                if ((document.getElementById(controlId))) {
                    document.getElementById(controlId).focus();
                }
            }, time);
        }
    }

    public static getKeyName(obj) {
        return ((obj)) ? Object.keys(obj)[0] : '';
    }

    public static regExPatern = {
        mobnum: "^((\\+91-?)|0)?[0-9]{10}$",
        number: '^(0|[1-9][0-9]*)$',
        gstnum: '^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$',
        decimal: "^[0-9]{1,12}(\.[0-9]{0,2})?$",
        usernamePattern: '^[a-z0-9_-]{8,15}$',
        pwdPattern: '^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[!@#$%^&*()_+=\[{\]};:<>|?,-]).{6,32}$',
        mobilePattern: '^((\\+91-?)|0)?[0-9]{10}$'
    }

    public static get getVendorCode() {
        if (localStorage.getItem('AuthorizeData')) {
            const localObj = JSON.parse(localStorage.getItem('AuthorizeData'))
            return localObj ? localObj['vendorCode'] : '';
        }
        return '';
    }
    
   
    public static validateNumber(value) {
        if ((value) && !isNaN(Number(value))) {
            return Number(value);
        } else {
            return 0;
        }
    }

    public static copyObject(v) {
        if ((v)) {
            return JSON.parse(JSON.stringify(v));
        } else {
            return v;
        }
    }

    
    public static printDiv(divName) {
        var printContents = document.getElementById(divName).innerHTML;
        const w=900;
        const h=600;
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth :
            document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight
            ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        const top = (height - h) / 2 / systemZoom + dualScreenTop;
        const printWin = window.open('', 'Print', 'scrollbars=yes, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left);
        printWin.document.write(printContents);
        printWin.document.close();
        printWin.focus();
        printWin.print();
        printWin.close();
    }

}

export class CommonMessages {
    static account_validation_messages = {
        'username': [
            { type: 'required', message: 'Username is required' },
            { type: 'minlength', message: 'Username must be at least 5 characters long' },
            { type: 'maxlength', message: 'Username cannot be more than 25 characters long' },
            { type: 'pattern', message: 'Your username must contain only numbers and letters' },
            { type: 'validUsername', message: 'Your username has already been taken' }
        ],
        'email': [
            { type: 'required', message: 'Email is required' },
            { type: 'pattern', message: 'Enter a valid email' }
        ],
        'confirm_password': [
            { type: 'required', message: 'Confirm password is required' },
            { type: 'areEqual', message: 'Password mismatch' }
        ],
        'password': [
            { type: 'required', message: 'Password is required' },
            { type: 'minlength', message: 'Password must be at least 5 characters long' },
            { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' }
        ],
        'terms': [
            { type: 'pattern', message: 'You must accept terms and conditions' }
        ]
    }
}

export class Logger {
    public static loggingEnabled = false;

    public static Normal(message: any, key: string = '') {
        if (this.loggingEnabled) {
            console.log(message, key);
        }
    }

    public static Error(message: any, key: string = '') {
        if (this.loggingEnabled) {
            console.error(message, key);
        }
    }

    public static Warn(message: any, key: string = '') {
        if (this.loggingEnabled) {
            console.warn(message, key);
        }
    }

    public static Info(message: any, key: string = '') {
        if (this.loggingEnabled) {
            console.info(message, key);
        }
    }
}

